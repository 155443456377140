<template>
	<v-app-bar dark fixed app color="#3f5fb9">
		<v-btn text plain x-large rounded :to="{ name: 'Landing' }" class="text-none beforeNoOacity">
			<v-avatar size="48" class="mr-4">
				<v-img src="@/assets/images/icon/logo-oversos-second-2x.png" />
			</v-avatar>

			<span style="font-family: Tahoma">OverSOS</span>
		</v-btn>
		<v-spacer />
		<v-btn v-if="$vuetify.breakpoint.mdAndUp" text rounded href="#home">
			{{ $t('nav.home') }}
		</v-btn>
		<v-btn v-if="$vuetify.breakpoint.mdAndUp" text rounded href="#about">
			{{ $t('nav.about') }}
		</v-btn>
		<v-btn v-if="$vuetify.breakpoint.mdAndUp" text rounded href="#features">
			{{ $t('nav.features') }}
		</v-btn>
		<v-btn v-if="$vuetify.breakpoint.mdAndUp" text rounded href="#pricing">
			{{ $t('nav.pricing') }}
		</v-btn>
		<v-btn v-if="$vuetify.breakpoint.mdAndUp" text rounded href="#contact">
			{{ $t('nav.contact') }}
		</v-btn>
		<v-btn v-if="$vuetify.breakpoint.mdAndUp" text rounded :to="{ name: 'Team' }">
			{{ $t('nav.team') }}
		</v-btn>
		<v-spacer />
		<v-btn text rounded :to="{ name: 'AuthRegister' }">
			{{ $t('auth.register') }}
		</v-btn>
	</v-app-bar>
</template>

<script>
export default {
	name: 'LandingHeader'
}
</script>

<style scoped>
.beforeNoOacity:before {
	opacity: 0;
}
</style>
